window.clientInterface = {
  handler: {}
};

clientInterface.ready = function() {
  console.log("Inside client interface...");
  var $masthead         = $('.masthead'),
      $topMenu          = $('.top.fixed.menu'),
      $mainSidebar      = $('.ui.sidebar.main.menu'),
      $dropdown         = $('.ui.dropdown'),
      handler
  ;
  
  handler = {
    init: function() {
      // Fix menu when passed
      $masthead.visibility({
        once: false,
        onBottomPassed: function() {
          $topMenu.transition('fade in');
        },
        onBottomPassedReverse: function() {
          $topMenu.transition('fade out');
        }
      });    
      
      // create sidebar
      $mainSidebar.sidebar('attach events', '.toc.item');
        
      $dropdown.dropdown();      
    }
  };
    
  clientInterface.handler = handler;
  handler.init();  
}