window.orderInterface = {
  handler: {}
};

orderInterface.ready = function() {
  console.log("Inside order interface...");
  var $orderTotalInput        = $(".order-total.input"),
      $orderAccountLimitInput = $(".order-account-limit.input"),
      $calendar               = $(".ui.calendar"),  
      handler
  ;
  
  handler = {
    checkLimit: function(element) {
      console.log("checking limit of the order");      

      let total = $orderTotalInput.val();
      let limit = $orderAccountLimitInput.val();
      
      let $td = $($orderTotalInput.closest("td"))      

      if ($.isNumeric(total) && $.isNumeric(limit)) {
        if (parseFloat(limit) < parseFloat(total)) {
          $td.addClass("error")
          $orderTotalInput.parent().addClass("error")
        } else {
          $td.removeClass("error")
          $orderTotalInput.parent().removeClass("error")        
        }
      } else {
        $td.removeClass("error")
        $orderTotalInput.parent().removeClass("error")        
      } 
    },
    checkReadyToSubmit: function() {
      let enabled = false;
      let $lineItems = $(".order-line-item.item")
      let $td = $($orderTotalInput.closest("td"))
      
      Array.from($lineItems).forEach(function(item) {
        if (item.classList.contains("error")) {
          return enabled = true;          
        }
      });
      
      if ($td.hasClass("error")) {
        enabled = true
      }
      
      // console.log("Enabled: " + enabled);
      let $submitButton = $(".ui.order.form .submit.button")
      if (enabled) {
        $submitButton.addClass("disabled");
      } else {
        $submitButton.removeClass("disabled");
      }
      
    },    
    init: function() {
      $orderTotalInput.on("change", function() {
        handler.checkLimit(this);
        handler.checkReadyToSubmit();        
      });
      $calendar.calendar({
        type: 'date'
      });      
      $(".ui.order.form").form({ inline: true })
      $(".ui.option-type-quantity.input").popup({ on: focus })
      $(".ui.variant-quantity.input").popup({ on: focus })
      $orderTotalInput.popup({ on: focus })
      handler.checkReadyToSubmit();
    }
  };
  
  orderInterface.handler = handler;
  handler.init();
}